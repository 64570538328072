/*
 * Copyright 2018 Red Hat, Inc. and/or its affiliates.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

 /* Remove weird focus around bootstrap buttons */
 .btn{
	box-shadow: none !important
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }
  .form-check-input{
    box-shadow: none !important
  }
  
  /* Animiation for Progress Circle */
  circle {
    transition: all 0.7s ease-in-out;
  }


 /* Changes position of x-close button on alerts */
  .alert-dismissible {
    margin-right: 100px;
    padding-right: 16px;
  }
  .alert-dismissible .btn-close {
    padding: 6px;
  }

/*  CSS for password dialog */
.password-modal .modal-content {
  background-color: rgb(238, 238, 238);
}

.password-modal .form-control:focus{
  border-color: #6c757d;
  box-shadow: none;
  background-color: transparent;
}

.DriverEditorModal{
  width: 750px;
  max-width: none !important;
  }

  .AddDriverModal{
    width: 450px;
    max-width: none !important;
    }

  .AddressEditorModal{
    width: 800px;
    max-width: none !important;
    }

    .AddressEditorModal .modal-backdrop {
      z-index: 2000;
    }

    .AlertBoxModal .modal-content {
      display: inline;
    }

    p.alertp {
      padding-left: 2em;
      text-indent: -2em;
      margin: 0;
  }